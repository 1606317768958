import { User2Icon, Users2Icon } from "lucide-react";

export const plans = [
  {
    stripePriceId: "price_1ODMUcKqaLcdpMYsOJ4j8XtA",
    Icon: <User2Icon className="flex h-10 w-10 rounded-full stroke-white/40" />,
    name: "PRO Personal",
    sub: "For individual developers and freelancers.",
    desc: "Beautifully crafted React + Tailwind CSS + Framer Motion components and templates for your next web project.",
    discountPrice: 49,
    isDiscounted: true,
    price: 129,
    isMostPop: true,
    banner: "$80 off",
    href: "https://buy.stripe.com/14k9DLgEcg2i6S45kq?prefilled_promo_code=SUMMERSALE",
    featureHeader: "What's included:",
    features: [
      "50+ sections (300 expected)",
      "2 templates (5 expected)",
      "1 developer license",
      "Commercial Use",
      "Perpetual license",
      "Lifetime access",
      "Lifetime updates",
    ],
  },
  {
    Icon: (
      <Users2Icon className="flex h-10 w-10 rounded-full stroke-white/40" />
    ),
    name: "PRO Teams",
    sub: "For product teams, agencies and startups.",
    desc: "Beautifully crafted React + Tailwind CSS + Framer Motion components and templates for your next web project.",
    discountPrice: 249,
    isDiscounted: true,
    price: 499,
    isMostPop: false,
    banner: "50% off",
    href: "https://buy.stripe.com/eVabLTafO5nE4JWbIN?prefilled_promo_code=SUMMERSALETEAM",
    featureHeader: "Everything in Personal, plus:",
    features: [
      "10 developer licenses",
      "Commercial Use",
      "Perpetual license",
      "Lifetime Access",
      "Lifetime Updates",
    ],
  },
  // {
  //   Icon: (
  //     <Users2Icon className="flex h-10 w-10 rounded-full stroke-white/40" />
  //   ),
  //   name: "PRO Enterprise",
  //   sub: "For product teams, agencies and startups.",
  //   desc: "Beautifully crafted React + Tailwind CSS + Framer Motion components and templates for your next web project.",
  //   discountPrice: 1049,
  //   isDiscounted: true,
  //   price: 1499,
  //   isMostPop: false,
  //   banner: "30% off",
  //   href: "https://buy.stripe.com/cN217fcnW7vM2BO9AK?prefilled_promo_code=SPRINGSALEENTERPRISE",
  //   featureHeader: "Everything in Personal, plus:",
  //   features: [
  //     "25+ developer licenses",
  //     "Commercial Use",
  //     "Perpetual license",
  //     "Lifetime Access",
  //     "Lifetime Updates",
  //   ],
  // },
];
